window.addEventListener("load", function () {
  document.querySelectorAll(".home-faq__toggle").forEach(function (toggle) {
    const header = toggle.querySelector(".home-faq__toggle-header");

    if (header) {
      header.addEventListener("click", function () {
        toggle.classList.toggle("home-faq__toggle--open");
      });
    }
  });
});
